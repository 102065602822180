






import { Vue, Component, Prop } from 'vue-property-decorator'
import { Fragment } from 'vue-fragment'
import UserLoginStore from '../../store/vuex/authentication/UserLoginStore'

@Component({
	components: {
		Fragment,
	},
})
export default class ComponentAuthGuard extends Vue  {
	@Prop({ type: [Array, String, Object], default: null }) requires!: string | string[] | null

	get hasAuthorization() {
		return UserLoginStore.temAutorizacao(this.requires)
	}
}

