




































































import { Vue, Component, Watch } from 'vue-property-decorator'
import { FindUsuarioUseCase } from '@/usecases'
import { UsuariosModule } from '@/store/vuex/usuario/UsuariosStore'
import ListaDeUsuarios from '@/components/usuario/ListaDeUsuarios.vue'
import FiltrosDeUsuario from '@/components/usuario/FiltrosDeUsuario.vue'
import TelaGenerica from '@/components/layout/TelaGenerica.vue'
import MenuDeFiltrosDeUsuario, { obterFiltroDeUsuarioDefault } from '@/components/usuario/MenuDeFiltroDeUsuario.vue'
import { FiltroDeBuscaDeUsuarios } from '@/models'
import axios, { CancelTokenSource } from 'axios'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'

@Component({
	components: {
		ListaDeUsuarios,
		FiltrosDeUsuario,
		TelaGenerica,
		MenuDeFiltrosDeUsuario,
	},
})
export default class TelaDeUsuarios extends Vue {
	termo = ''
	page = 1
	totalPages = 1
	findUsuarioUseCase = new FindUsuarioUseCase()
	filtro: FiltroDeBuscaDeUsuarios = obterFiltroDeUsuarioDefault()
	cancelToken: CancelTokenSource | null = null
	carregando = false

	created() {
		this.buscarUsuarios()
	}

	async buscarUsuarios() {
		if(this.cancelToken) this.cancelToken.cancel()
		try{
			this.carregando = true
			this.cancelToken = axios.CancelToken.source()

			const users = await this.findUsuarioUseCase.findAll({
				page: this.page -1,
				size: 10,
				perfis: this.filtro.perfis.map(({ id }) => id),
				lojas: this.filtro.lojas.map(({ id }) => id),
				nome: this.termo || undefined,
			}, {
				cancelToken: this.cancelToken.token,
			})
			UsuariosModule.setUsuarios(users.content)
			this.totalPages = users.totalPages
		} catch(error){
			if(axios.isCancel(error)) return
			AlertModule.setError(error)
		} finally{
			this.carregando = false
		}
	}

	@Watch('termo')
	setTermo() {
		this.buscarUsuarios()
	}

	get usuariosFiltrados() {
		return UsuariosModule.usuariosFiltrados
	}

	@Watch('filtro', { deep: true })
	onChangeFiltro() {
		this.buscarUsuarios()
	}

	@Watch('page')
	onChangePage = this.buscarUsuarios
}
