








import { Vue, Component, Prop } from 'vue-property-decorator'
import ComponentAuthGuard from './ComponentAuthGuard.vue'
import type { TipoDeRecurso, TipoDeOperacao } from '../../models/Recurso'
import { montarOperacaoDeRecurso } from '../../shareds/permissoes-shareds'

@Component({
	components: {
		ComponentAuthGuard,
	},
})
export default class ComponentResourceGuard extends Vue {
	@Prop({ type: String }) recurso!: TipoDeRecurso
	@Prop({ type: String }) operacao!: TipoDeOperacao

	get requires() {
		if (!this.recurso || !this.operacao) return null
		return montarOperacaoDeRecurso(this.operacao, this.recurso)
	}
}

